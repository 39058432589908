<script>
    import { required, minLength } from "vuelidate/lib/validators";
    import Auth from '@/services/Auth';
    import validationMessages from '@/components/validations'


    export default {
        components:{
            validationMessages
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                username: '',
                password: '',
                role_options: [],
                role: '',
                submitted: false,
                showModal: false,
                tryingToSubmit: false,
                showLoader: false,
                showPassword:false,
            };
        },
        validations: {
            username: {
                required
            },
            password: {
                required,
                minLength: minLength(8),
                valid: function(value) {
                    const containsUppercase = /[A-Z]/.test(value)
                    const containsLowercase = /[a-z]/.test(value)
                    const containsNumber = /[0-9]/.test(value)
                    const containsSpecialCharacter = /[!#$%&'()*+,-.:;<=>?@^_`{|}~]/.test(value);
                    return containsUppercase && containsLowercase && containsNumber && containsSpecialCharacter
                }
            },
            role: {
                required
            },
        },
        methods: {

            async getRoles(){
                this.showLoader = true
                await Auth.getRoles().then((response) => {
                    this.role_options = response.data.data
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }).finally(() => {
                    this.showLoader = false
                })
            },

            async addUser(){
                this.tryingToSubmit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToSubmit = false;
                    return;
                } else {
                    try {
                        await Auth.addUser({
                            username: this.username,
                            password: this.password,
                            role: this.role,
                            role_name: this.getRoleNameById(this.role),
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            const res   = response.data.created  ? response.data.created  : false;
                            const error = response.data.error ? response.data.error : '';
                            if(res && error==''){
                                this.successmsg("New user successfully created");
                                this.closeModal();
                            }else{
                                this.failedmsg("Failed to create")
                            }

                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToSubmit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("User not saved")
                    }
                }
                this.tryingToSubmit = false;
            },

            getRoleNameById(roleId) {
                const selectedRole = this.role_options.find((roleOption) => roleOption.id === roleId);
                return selectedRole ? selectedRole.name : '';
            },

            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
                this.username = ''
                this.password = ''
                this.role = ''
                this.role_name = ''
            },

            initialData(){
                this.getRoles()
            },
            toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
        }
    };
</script>

<template>
    <b-modal @shown="initialData" v-model="showModal" id="add_user" title="Add new User" title-class="font-18" size="lg" centered>
        <form @submit.prevent="addUser" v-if="!showLoader">
            <div class="row">
                <div class="col">
                    <b-form-group label="Username" label-for="formrow-username-input" class="mb-3">
                        <b-form-input
                                v-model.trim="username"
                                type="text"
                                aria-describedby="username-feedback"
                                autocomplete="new-username"
                                aria-autocomplete="none"
                                :class="{
                                  'is-invalid': submitted && $v.username.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Username'" :validationName="$v.username"></validationMessages>
                    </b-form-group>

                    <b-form-group label="Password" label-for="formrow-password-input" class="mb-3">
                        <b-input-group>
                        <b-form-input
                                v-model.trim="password"
                                :type="showPassword ? 'text' : 'password'"
                                aria-describedby="password-feedback"
                                autocomplete="new-password"
                                aria-autocomplete="none"
                                :class="{
                                  'is-invalid': submitted && $v.password.$error,
                                }"
                        >
                        </b-form-input>
                        <b-input-group-append>
                            <b-button variant="btn btn-outline-secondary" @click="toggleShowPassword">
                              <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <validationMessages v-if="submitted" :fieldName="'Password'" :validationName="$v.password"></validationMessages>
                    </b-form-group>

                    <b-form-group label="Role" label-for="formrow-role-input" class="mb-3">
                        <select v-model="role" class="form-select"
                            :class="{
                                      'is-invalid': submitted && $v.role.$error,
                                    }"
                        >
                            <option v-for="role in role_options" :key="role.id" :value="role.id">{{role.name}}</option>
                        </select>
                        <validationMessages v-if="submitted" :fieldName="'Role'" :validationName="$v.role"></validationMessages>
                    </b-form-group>

                </div>
            </div>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>
        <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
        </div>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addUser" :disabled="tryingToSubmit || showLoader">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>